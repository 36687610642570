export const theme = {
  colors: {
    white: '#fff',
    gray: '#ddd',
    graybd: '#c5ccd0',
    blackgray: 'rgb(159, 159, 159)',
    whitegray: '#f3f3f3',
    green: '#009879',
    green_bg: '#005242',
    yellowgreen: 'rgb(159, 227, 152)',
    yellow: "#ffcc00",
    blue: '#007bff',
    bluebg: '#f4f9ff',
    red: 'red',
    black: '#000',
    disabled: 'gray',
    black_bg: '#1f1f1f',
    black_bg2: "#2f3034",
    black_bg_trans: "rgba(0, 0, 0, 0.7)",
    trans: "transparent",
    orange: "orange",
  }
}
