import React from 'react';
import Router from './Router';
import GlobalStyles from './styles/GlobalStyles'

function App() {
  return (
    <>
      <GlobalStyles />
     
     
      <h1>여기서부터 시작할거야</h1>
     
      <h1 style={{ color: 'red'}}>글로벌 css 설정</h1>
      <h1 style={{ color: 'red'}}>라우터 시작</h1>
      <h1>권한별 관리 시작</h1>
      <h1>주스탠드 해결</h1>
      <h1>리엑트쿼리 해결</h1>
      <h1>스타일컴포넌트 해결</h1>
      <h1>파일업로드 </h1>
      <h1>AXios 해결</h1>

      <h1>우선 이것부터</h1>
      <Router />
    </>
  );
}

export default App;
