import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'

const GlobalStyles = createGlobalStyle`
  /* 전역 스타일 초기화 */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* 기본 폰트 스타일 */
  body {
    font-family: 'Arial', sans-serif;
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    line-height: 1.6;
  }

  /* 버튼 스타일 초기화 */
  button {
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
  }

  /* 링크 스타일 초기화 */
  a {
    text-decoration: none;
    color: inherit;
  }
  li {
    list-style: none;
  }

  input {
    min-width: 0;
  }
  table {
    border-collapse: collapse;
  }
  svg {
    pointer-events: none;
  }

  /* 스크롤바 */
  &::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${theme.colors.green};
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: ${theme.colors.black_bg2};
  }
  &::-webkit-scrollbar-corner {
    background: ${theme.colors.black_bg2};
  }
  &.SCROLL_BG_TRANS {
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  /* 차트 wrapper */
  .wrapper-chart {
    &.width-full {

      & > canvas {
        max-width: 100%;
      }
    }
  }
`

export default GlobalStyles
